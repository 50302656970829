<template>
  <div class="config-param">
    <div class="config-param-cont">
      <div class="tit">请确认/修改试卷配置参数</div>
      <div class="sub-tit">配置参数:</div>
      <div class="display_flex mt10">
        <span class="label-name">试卷标题: </span>
        <div>
          <Input v-model="info.name" :disabled="!isEdit" placeholder="请输入试卷标题" style="width: 470px" />
        </div>
      </div>
      <div class="display_flex mt10">
        <span class="label-name">考试时间: </span>
        <div class="display_flex">
          <Input v-model="info.timelong" :disabled="!isEdit" placeholder="请输入考试时间" class="mr10 w120" />
          <span>分钟</span>
        </div>
      </div>
      <div class="display_flex">
        <div class="display_flex mt10">
          <span class="label-name">试卷总分: </span>
          <div class="display_flex">
            <Input v-model="info.total_score" disabled placeholder="请输入试卷总分" class="mr10 w120" />
            <span class="w68">分</span>
          </div>
        </div>
        <div class="display_flex mt10">
          <span class="label-name">及格分数: </span>
          <div class="display_flex">
            <Input v-model="info.pass_score" :disabled="!isEdit" placeholder="请输入及格分数" class="mr10 w120" />
            <span>分</span>
          </div>
        </div>
      </div>
      <!--    <div class="sub-tit">进入方式:</div>-->
      <!--    <div class="display_flex mt10">-->
      <!--      <div class="display_flex mr10">-->
      <!--        <img src="../../../../../assets/images/new_icon/wxdanxuan.png" width="16" height="16" class="mr10" />-->
      <!--        <p class="w68">随时进入</p>-->
      <!--      </div>-->
      <!--      <div class="display_flex">-->
      <!--        <img src="../../../../../assets/images/new_icon/xzdanxuan.png" width="16" height="16" class="mr10" />-->
      <!--        <p>固定时间进入（默认开始30分钟后禁止入内）</p>-->
      <!--      </div>-->
      <!--    </div>-->
      <div class="display_flex mt10">
        <span class="label-name">考试开始日期: </span>
        <el-date-picker
          @change="startTimeChange"
          size="small"
          v-model="info.startTime"
          type="datetime"
          placeholder="选择开始日期"
          :picker-options="dateOptions"
          align="right">
        </el-date-picker>
        <div style="margin-left: 10px;" v-show="isEdit">
          <el-button size="small" type="text" @click="getServerTime(1)">立即开始</el-button>
          <el-button size="small" type="text" @click="getServerTime(2)">下个整点</el-button>
          <el-button size="small" type="text" @click="getServerTime(3)">整体延后1小时</el-button>
        </div>
      </div>
      <div class="display_flex mt10">
        <span class="label-name">考试结束日期: </span>
        <el-date-picker
        @change="endTimeChange"
          size="small"
          v-model="info.endTime"
          type="datetime"
          placeholder="选择结束日期"
          :picker-options="dateOptions"
          align="right">
        </el-date-picker>
      </div>
      <!-- <div class="display_flex mt10">
        <span class="label-name">考试日期: </span>
        <DatePicker v-model="info.setDate" :disabled="!isEdit" :options="dateOptions" type="datetimerange"
          format="yyyy-MM-dd HH:mm" placeholder="请选择时间" style="width: 300px"></DatePicker>
        <div style="margin-left: 10px;" v-show="isEdit">
          <el-button size="small" type="text" @click="getServerTime(1)">立即开始</el-button>
          <el-button size="small" type="text" @click="getServerTime(2)">下个整点</el-button>
          <el-button size="small" type="text" @click="getServerTime(3)">整体延后1小时</el-button>
        </div>
      </div> -->
      <div class="sub-tit">做题方式:</div>
      <div class="display_flex mt10">
        <div class="display_flex mr10" v-for="item in typeList" :key="item.id" @click="changeType(item)">
          <img src="../../../../../assets/images/new_icon/xzdanxuan.png" v-if="item.id == info.random_data_sort"
            width="16" height="16" class="mr10" />
          <img src="../../../../../assets/images/new_icon/wxdanxuan.png" v-else width="16" height="16" class="mr10" />
          <p class="w68" style="cursor: pointer">{{ item.name }}</p>
        </div>
        <!--      <div class="display_flex">-->
        <!--        <img src="../../../../../assets/images/new_icon/xzdanxuan.png" width="16" height="16" class="mr10" />-->
        <!--        <p>顺序做题</p>-->
        <!--      </div>-->
      </div>

      <div class="sub-tit" style="margin-bottom: 10px;">考试监控</div>
      <div class="camera-mic">
        <div class="monitor-title">是否开启视频监控</div>
        <el-switch style="margin-left: 10px;" v-model="is_open_monitor" @change="monitorChange"></el-switch>
      </div>
      <div class="camera-mic">
        <div class="monitor-title">是否开启屏幕监控</div>
        <el-switch style="margin-left: 10px;" v-model="is_open_screen" @change="screenChange"></el-switch>
      </div>
      <div class="camera-mic">
        <div class="monitor-title">防切屏</div>
        <el-switch style="margin-left: 10px;" v-model="is_open_limit_cut" @change="limitCutChange"></el-switch>
      </div>
      <div class="camera-mic">
        <div class="monitor-title">
          人脸识别
          <el-tooltip effect="dark" content="定时捕捉用户人脸图像并识别异常行为，本功能必须开启视频监控" placement="top">
            <div class="el-icon-question"></div>
          </el-tooltip>
        </div>
        <el-switch style="margin-left: 10px;" v-model="is_open_face_identify" @change="faceChange"></el-switch>
      </div>
      <div class="camera-mic">
        <div class="monitor-title">
          屏幕识别
          <el-tooltip effect="dark" content="定时捕捉用户屏幕图像并识别异常行为" placement="top">
            <div class="el-icon-question"></div>
          </el-tooltip>
        </div>
        <el-switch style="margin-left: 10px;" v-model="is_open_screen_identify" @change="screenIdentifyChange"></el-switch>
      </div>
      <div class="sub-tit" style="margin-bottom: 10px;">显示端</div>
      <div class="camera-mic">
        <div class="monitor-title">是否在PC端显示</div>
        <el-switch style="margin-left: 10px;" v-model="is_show_pc"></el-switch>
      </div>
      <div class="camera-mic">
        <div class="monitor-title">是否在小程序展示</div>
        <el-switch style="margin-left: 10px;" v-model="is_show_xcx" :disabled="is_open_monitor || is_open_screen"></el-switch>
      </div>


      <div class="sub-tit">答题说明:</div>
      <Input v-model="info.content" type="textarea" :disabled="!isEdit" key="inputArea" :rows="6" placeholder="Enter something..."
        style="width: 540px" class="mt10" />
      <!--    <div class="sub-tit">选择推送的班级:</div>-->
      <!--    <div class="display_flex mt10">-->
      <!--      <div class="display_flex mr10">-->
      <!--        <img src="../../../../../assets/images/new_icon/checkbox.png" width="16" height="16" class="mr10" />-->
      <!--        <p class="w68">班级一</p>-->
      <!--      </div>-->
      <!--      <div class="display_flex mr10">-->
      <!--        <img src="../../../../../assets/images/new_icon/select_checkbox.png" width="16" height="16" class="mr10" />-->
      <!--        <p class="w68">班级二</p>-->
      <!--      </div>-->
      <!--      <div class="display_flex mr10">-->
      <!--        <img src="../../../../../assets/images/new_icon/select_checkbox.png" width="16" height="16" class="mr10" />-->
      <!--        <p class="w68">班级二</p>-->
      <!--      </div>-->
      <!--    </div>-->
      <div class="bot-btn" v-if="isEdit">
        <div class="active" @click="pre">上一步</div>
        <div @click="save">保存并退出</div>
        <div class="active" @click="publish">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
// import util from '@/utils/tools.js';
export default {
  name: "configParam.vue",
  data() {
    return {
      value: '',
      animal: '',
      answerExp: '1. 时间安排：请同学们提前规划好答题时间，合理安排复习和休息时间。\n' +
        '2. 复习重点：请同学们重点复习教材中的重点知识点和难点，同时也要注意平时作业和练习题的复习。\n' +
        '3. 考试纪律：请同学们遵守纪律，不得抄袭、作弊等违规行为；\n' +
        '4. 考试心态：请同学们保持良好的考试心态，不要过于紧张或放松。',
      info: {
        arrt:{
          content:"",
        },
      },
      typeList: [
        {
          name: '打乱做题',
          id: '1'
        },
        {
          name: '顺序做题',
          id: '0'
        }
      ],
      dateOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        }
      },
      originTime: [],

      // 视频实时监控
      is_open_monitor:false,
      // 屏幕实时监控
      is_open_screen:false,
      // 防切屏
      is_open_limit_cut:false,
      // 人脸识别
      is_open_face_identify:false,
      // 屏幕识别
      is_open_screen_identify:false,
      is_show_pc:true,
      is_show_xcx:true,
    }
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    },
    trainId: {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: true,
    }
  },
  watch: {
    detail: {
      handler(newV) {
        if (newV.id) {
          this.info = {...newV};
          if(this.info.is_open_monitor==="1"){
            this.is_open_monitor=true;
          }else{
            this.is_open_monitor=false;
          }
          if(this.info.is_open_screen==="1"){
            this.is_open_screen=true;
          }else{
            this.is_open_screen=false;
          }
          if(this.info.is_open_limit_cut==="1"){
            this.is_open_limit_cut=true;
          }else{
            this.is_open_limit_cut=false;
          }
          if(this.info.is_open_face_identify==="1"){
            this.is_open_face_identify=true;
          }else{
            this.is_open_face_identify=false;
          }
          if(this.info.is_open_screen_identify==="1"){
            this.is_open_screen_identify=true;
          }else{
            this.is_open_screen_identify=false;
          }
          if(this.info.is_show_pc==="1"){
            this.is_show_pc=true;
          }else{
            this.is_show_pc=false;
          }
          if(this.info.is_show_xcx==="1"){
            this.is_show_xcx=true;
          }else{
            this.is_show_xcx=false;
          }
          this.info.content=newV?.attr?.content;
          this.initData();
        }
      },
      immediate: true,
    }
  },
  methods: {
    // 立即开始|下个整点|整体延后一小时
    getServerTime(type) {
      const { info, originTime } = this;
      if (type === 1) {
        // info.setDate = [util.timeFormatter(
        //   new Date(+this.info.present_time_stamp * 1000),
        //   'yyyy-MM-dd hh:mm:ss'
        // ), originTime[1]];
        info.startTime=new Date(this.info.present_time_stamp * 1000);
      }
      if (type === 2) {
        // info.setDate = [util.timeFormatter(
        //   new Date(+this.info.next_hour_time_stamp * 1000),
        //   'yyyy-MM-dd hh:mm:ss'
        // ), originTime[1]];
        info.startTime=new Date(this.info.next_hour_time_stamp * 1000);
      }
      if (type === 3) {
        const start = new Date(originTime[0]).getTime()+ 60 * 60 * 1000;
        const end = new Date(originTime[1]).getTime() + 60 * 60 * 1000;
        info.startTime=new Date(start);
        info.endTime=new Date(end);

        // info.setDate = [util.timeFormatter(new Date(+start), "yyyy-MM-dd hh:mm:ss"),
        // util.timeFormatter(new Date(+end), "yyyy-MM-dd hh:mm:ss")]
      }
    },
    // 开始时间改变
    startTimeChange(date){
      const { info } = this;
      if(!date) return;
      if(!info.endTime) return;
      if(date.getTime()>=info.endTime){
        this.$Message.error("开始日期不能大于结束日期");
        info.startTime=null;
      }
    },
    endTimeChange(date){
      const {info}=this;
      if(!date) return;
      if(!info.startTime) return;
      if(date.getTime()<=info.startTime){
        this.$Message.error("结束日期不能小于开始日期");
        info.endTime=null;
      }
    },
    initData() {
      if (this.info.start_time && this.info.end_time) {
        // let setDate = [util.timeFormatter(
        //   new Date(+this.info.start_time * 1000),
        //   'yyyy-MM-dd hh:mm:ss'
        // ), util.timeFormatter(
        //   new Date(+this.info.end_time * 1000),
        //   'yyyy-MM-dd hh:mm:ss'
        // )];
        // this.$set(this.info, 'setDate', setDate);
        this.info.content = this.info.content || this.answerExp;
        this.originTime = [this.info.start_time*1000,this.info.end_time*1000];
        // this.info.random_data_sort


        this.$set(this.info,"startTime",this.info.start_time*1000);
        this.$set(this.info,"endTime",this.info.end_time*1000);
      }
    },
    changeType(data) {
      if (!this.isEdit) return;
      this.info.random_data_sort = data.id;
    },
    submit(type, fn) { //提交数据
      if (!this.info.name) {
        this.$Message.warning('请输入试卷标题');
        return;
      }
      if (!this.info.timelong) {
        this.$Message.warning('请输入考试时间');
        return;
      }
      if (!this.info.total_score) {
        this.$Message.warning('请输入试卷总分');
        return;
      }
      if (!this.info.pass_score) {
        this.$Message.warning('请输入及格分数');
        return;
      }

      // if (!this.info.setDate.length) {
      //   this.$Message.warning('请选择设置时间');
      //   return;
      // }
      if (!this.info.startTime) {
        this.$Message.warning('请选择开始日期');
        return;
      }
      if (!this.info.endTime) {
        this.$Message.warning('请选择结束日期');
        return;
      }
      let startData = new Date(this.info.startTime).getTime();
      let endDate = new Date(this.info.endTime).getTime();

      if (endDate - startData < this.info.timelong * 60 * 1000) {
        this.$Message.warning('考试结束时间不能小于开始时间+考试时长');
        return;
      }

      if (!this.info.random_data_sort) {
        this.$Message.warning('请选择做题方式');
        return;
      }
      let params = {
        op: 'baseInfo',
        name: this.info.name,
        timelong: this.info.timelong,
        total_score: this.info.total_score,
        pass_score: this.info.pass_score,
        // start_time: new Date(this.info.setDate[0]).getTime() / 1000,
        // end_time: new Date(this.info.setDate[1]).getTime() / 1000 + 86400 - 1,
        random_data_sort: this.info.random_data_sort,
        content: this.info.content,
        train_id: this.trainId,
        start_time:this.info.startTime/1000,
        end_time:this.info.endTime/1000,
        is_open_monitor:this.is_open_monitor?"1":'0',
        is_open_screen:this.is_open_screen?"1":"0",
        is_show_pc:this.is_show_pc?"1":"0",
        is_show_xcx:this.is_show_xcx?"1":"0",
        is_open_limit_cut:this.is_open_limit_cut?"1":"0",
        is_open_face_identify:this.is_open_face_identify?"1":"0",
        is_open_screen_identify:this.is_open_screen_identify?"1":"0"
      };
      if (type == 'next') {   //下一步
        this.nextLoading = true;
      } else if (type == 'save') { //保存并退出
        this.saveLoading = true;
      }
      this.api.dataset.trainPublish(params).then((res) => {
        if (type == 'next') {   //下一步
          this.nextLoading = false;
          this.$emit('next', { trainId: res.train_id });
        } else if (type == 'save') { //保存并退出
          this.saveLoading = false;
          this.$emit('save');
        }
        fn && fn();
      })
    },
    release(type) {
      let params = {
        op: 'release',
        train_id: this.trainId,
      };

      this.api.dataset.trainPublish(params).then((res)=>{
        this.$emit('publish');
      })
    },
    publish() {
      if (!this.isEdit) return;
      this.submit('publish', () => {
        this.release();
      });
    },
    save() {
      this.submit('save');
    },
    pre() {
      this.$emit('pre')
    },
    monitorChange(value){
      if(value){
        this.is_show_xcx=false;
        this.$Message.warning("开启考试监控，无法在小程序端显示");
      }else{
        this.is_open_screen=false;
        this.is_open_face_identify=false;
        this.is_open_limit_cut=false;
        this.is_open_screen_identify=false;
      }
    },
    screenChange(value){
      if(value){
        if(this.is_show_xcx===true){
          this.is_show_xcx=false;
        }

        if(this.is_open_monitor===false){
          this.is_open_monitor=true;
        }
      }
    },
    faceChange(value){
      if(value){
        this.is_show_xcx=false;
        this.is_open_monitor=true;
      }
    },
    screenIdentifyChange(value){
      if(value){
        this.is_open_monitor=true;
        this.is_show_xcx=false;
      }
    },
    limitCutChange(value){
      if(value){
        this.is_open_monitor=true;
        this.is_show_xcx=false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.config-param {
  margin: 0 20px;
  padding-top: 10px;
  height: 100%;
  font-size: 14px;

  .config-param-cont {
    padding: 20px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;

    .tit {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    .sub-tit {
      margin-top: 24px;
      font-weight: bold;
      color: #5782FF;
    }
    .camera-mic{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .monitor-title{
        width: 140px;
      }
    }

    .label-name {
      margin-right: 10px;
      font-weight: bold;
      color: #333333;
    }

    .display_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .mt10 {
      margin-top: 10px;
    }

    .mr10 {
      margin-right: 10px;
    }

    .w120 {
      width: 120px;
    }

    .w68 {
      display: inline-block;
      width: 68px;
    }

    .bot-btn {
      margin-top: 60px;
      text-align: right;
      display: flex;
      justify-content: flex-end;

      >div {
        margin-left: 15px;
        width: 90px;
        height: 36px;
        border: 1px solid #999999;
        border-radius: 4px;
        color: #999999;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }

      .active {

        background: #4A79FF;
        border-radius: 4px;
        border: none;
        color: #FFFFFF;
      }
    }
  }
}
</style>
