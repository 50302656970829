<template>
  <div class="dataList">
    <div class="dataList-l">
      <div class="dataList-l-top">
        <p>组卷试题题目预览</p>
        <div class="dataList-l-top-r">
          <div class="dataList-l-top-r-btn" @click="regenerate()">
            <img src="../../../../../assets/images/new_icon/suiji.png" width="14" height="12" />
            <p>全部随机更换</p>
          </div>
          <div class="dataList-l-top-r-btn active" @click="addData">
            <p>添加试题</p>
          </div>
        </div>
      </div>
      <div class="dataList-cont-list">
        <div class="dataList-cont-list-item" v-for="item in dataList" :key="item.type">
          <div class="dataList-cont-list-item-tit"  @click="showItem(item)">
            <p></p>
            <div>{{ item.name }} ({{ item.list.length }}题)</div>
            <img src="../../../../../assets/images/new_icon/shang.png" width="10" height="8" :style="{transform:item.show ? 'rotate(0deg)' : 'rotate(180deg)'}" />
          </div>
          <div class="dataList-cont-list-item-div" style="overflow: hidden" :style="{height: item.show ? (item.list.length + 1) * 45 + 'px' : '0px'}">
            <table class="dataList-cont-list-item-table">
              <tr>
                <th>题目</th>
<!--                <th>难度</th>-->
                <th>操作</th>
              </tr>
              <tr v-for="sItem in item.list" :key="sItem.id">
                <td class="data-tit" @click="showDetail(sItem)">
                  <p class="data-type" :class="sItem.type == 1 ? 'bjFD0' : sItem.type == 2 ? 'purple' : 'bj3ff' ">{{ typesObj[sItem.type] }}</p>
                  <div>{{ sItem.title }}</div>
                </td>
<!--                <td>{{ levelsObj[sItem.level] }}</td>-->
                <td class="data-btn" >
                  <div @click="regenerate({id:sItem.id})">随机更换</div>
                  <img src="../../../../../assets/images/new_icon/shanchu.png" width="18" height="18" @click="dele(sItem)" />
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>

    </div>
    <div class="dataList-r">
      <div class="dataList-r-tit">请选择试卷生成方式</div>
<!--      <div class="dataList-r-num">已选章节共计可抽取<span>503题</span>生成试卷</div>-->
      <div class="dataList-r-cont">
        <p class="dataList-r-cont-tit">题总数：</p>
        <div class="dataList-r-cont-nums">
          <div class="dataList-r-cont-num">{{dataCount}}</div>
          <span>题</span>
        </div>
      </div>
      <div class="dataList-r-cont">
        <p class="dataList-r-cont-tit">题型分布：</p>

        <div class="dataList-r-cont-ratio">
          <div class="dataList-r-cont-ratio-div" v-for="item in dataTypes" :key="item.id">
            <p class="dataList-r-cont-ratio-type">{{item.name}}</p>
            <p>{{ item.val }}题</p>
          </div>
<!--          <div class="dataList-r-cont-ratio-div">-->
<!--            <p class="dataList-r-cont-ratio-type">多选</p>-->
<!--            <p>{{typeStat[2] || 0}}题</p>-->
<!--          </div>-->
<!--          <div class="dataList-r-cont-ratio-div">-->
<!--            <p class="dataList-r-cont-ratio-type">判断</p>-->
<!--            <p>{{typeStat[3] || 0}}题</p>-->
<!--          </div>-->
        </div>

      </div>
      <div class="dataList-r-cont">
        <p class="dataList-r-cont-tit">设置题型分数：共计{{totalScore}}分</p>

        <div class="dataList-r-cont-ratio">
          <div class="dataList-r-cont-ratio-div" v-for="(item,index) in dataTypes" :key="item.id">
            <p class="dataList-r-cont-ratio-type">{{item.name}}/题</p>
            <el-input v-model="item.score" :disabled="!isEdit" placeholder="请输入" @input="checkScore(index)" style="width: 80px;margin: 5px 0;" />
            <p>共{{item.val * item.score * 10 / 10}}分</p>
          </div>
          <!--          <div class="dataList-r-cont-ratio-div">-->
          <!--            <p class="dataList-r-cont-ratio-type">多选</p>-->
          <!--            <p>{{typeStat[2] || 0}}题</p>-->
          <!--          </div>-->
          <!--          <div class="dataList-r-cont-ratio-div">-->
          <!--            <p class="dataList-r-cont-ratio-type">判断</p>-->
          <!--            <p>{{typeStat[3] || 0}}题</p>-->
          <!--          </div>-->
        </div>

      </div>

<!--      <div class="dataList-r-cont">-->
<!--        <p class="dataList-r-cont-tit">设置难度比例：</p>-->
<!--        <div class="dataList-r-cont-ratio">-->
<!--          <div class="dataList-r-cont-ratio-div">-->
<!--            <p class="dataList-r-cont-ratio-type">简单</p>-->
<!--            <p>20题</p>-->
<!--          </div>-->
<!--          <div class="dataList-r-cont-ratio-fg">:</div>-->
<!--          <div class="dataList-r-cont-ratio-div">-->
<!--            <p class="dataList-r-cont-ratio-type">一般</p>-->
<!--            <p>20题</p>-->
<!--          </div>-->
<!--          <div class="dataList-r-cont-ratio-fg">:</div>-->
<!--          <div class="dataList-r-cont-ratio-div">-->
<!--            <p class="dataList-r-cont-ratio-type">困难</p>-->
<!--            <p>20题</p>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
      <div class="dataList-r-btn" v-if="isEdit">
        <div class="active" @click="pre">上一步</div>
        <div @click="save">保存并退出</div>
        <div class="active" @click="next">下一步</div>
      </div>
    </div>
    <Modal
        v-model="addDataModal"
        title="请选择你要添加的试题"
        @on-cancel="closeAddDataModal"
        footer-hide
        width="1000px"
    >
      <div class="add-data-tab">
        <p :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</p>
      </div>
      <Input v-model="keyword"
             placeholder="输入关键词/知识点搜索"
             clearable
             :enter-button="true"
             @on-enter="changeKeyword"
             @on-search="changeKeyword"
             style="width: 300px">
        <div slot="suffix" class="search-img">
          <img  src="../../../../../assets/images/new_icon/sousuo.png" width="18" height="18" />
        </div>
      </Input>
      <div class="search" v-if="curTab.id == 2">
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeFirst(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSecond(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            三级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curThirdCategoryId == item.id ? 'active' : ''" v-for="item in thirdCategoryList" :key="item.id + 'thirdCategory'" @click="changeThird(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <Table :columns="columns" :data="allDataList" :loading="loading"></Table>
      </div>
      <div class="page">
        <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
      </div>
    </Modal>
    <Modal
        v-model="dataDetailModal"
        title="题目详情"
        @on-cancel="closeDataDetail"
        footer-hide
        width="1000px"
    >
      <DataDetail v-if="dataDetailModal" :dataId="curData.id"></DataDetail>
    </Modal>
    <DeleModal :status="deleModalStatus" txt="确认删除此题吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  </div>
</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
import DataDetail from './dataDetail.vue';
import util from '@/utils/tools.js';
export default {
  name: "dataList.vue",
  data(){
    return {
      addDataModal:false,
      keyword:'',
      page:1,
      pageSize:10,
      total:0,
      columns:[
        {
          title: '题型',
          width:120,
          render:(h,params)=>{
            return h('p',{
              class:{
                'bj3ff modal-render-data-type' : params.row.type == 3 || params.row.type == 4 || params.row.type == 8 || params.row.type == 9,
                'bjFD0 modal-render-data-type' : params.row.type == 1,
                'purple modal-render-data-type' : params.row.type == 2,
              }
            },this.typesObj[params.row.type])
          }

        },
        {
          title: '题目',
          key: 'title'
        },
        // {
        //   title: '难度',
        //   key: 'name',
        //   render:(h,params)=>{
        //     return h('p',this.levelsObj[params.row.level])
        //   }
        // },
        {
          title: '操作',
          width:120,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.selected == '1'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.addDataCur = params.row;
                    this.addCurData();
                  }
                }
              }, '添加'),
            ]);
          }
        },
      ],
      dataList:[],
      allDataList:[],
      typesObj:{},
      levelsObj:{},
      deleModalStatus:false,
      loading:false,
      curData:{},
      modalLoading:false,
      addDataCur:{},
      nextLoading:false,
      saveLoading:false,
      typeStat:{},
      dataCount:'',
      dataDetailModal:false,
      dataTypes:[],
      info:{},
      tabList:[
        {
          name:'当前题库',
          id:'1'
        },
        {
          name:'所有题库',
          id:'2'
        },
      ],
      curTab:{
        name:'当前题库',
        id:'1'
      },
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      curThirdCategoryId:'-1',//当前三级分类
      thirdCategoryList:[],
    }
  },
  props:{
    trainId:{
      type:String,
      default:'',
    },
    detail:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    isEdit:{
      type:Boolean,
      default:true,
    },
    categoryId:{
      type:String,
      default:'',
    },
    countStatus:{
      type:Boolean,
      default:true,
    }
  },
  components:{
    DeleModal,
    DataDetail
  },
  watch:{
    countStatus(newV){
      if(newV){
        this.regenerate({regenerateFill:1,})
      }
    },
    detail:{
      handler(newV,oldV){
        if(newV.id){
          this.info = newV;
        }
      },
      immediate:true,
    }
  },
  created(){
    this.getList();
    this.getDataList();
  },

  computed:{
    totalScore(){
      let num = 0;
      this.dataTypes.forEach((item)=>{
        num += Number(item.val * item.score);
      })
      return num;
    }
  },
  methods:{
    formatData(){
      let arr = this.info.score_str.split(',') || [];
      this.dataTypes.forEach((item,index)=>{
        console.log(item,'ddd')
        item.score = item.val == 0 ? 0 : (arr[index] || 0);
      })
    },
    getList(){
      let params = {
        train_id:this.trainId,
        page_size:999
      };
      this.loading = true;
      this.api.dataset.trainDataList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.listByGroup;

        this.dataList.forEach((item)=>{
          this.$set(item,'show',true);
        })


        this.levelsObj = res.data_levels;

        this.typesObj = res.data_types;

        this.trainDataStatusesObj = res.trainDataStatuses;
        this.selectDataList = [];


        this.typeStat = res.typeStat;

        this.dataTypes = [];
        for(let name in res.data_types){
          this.dataTypes.push({
            name:res.data_types[name],
            id:name,
            val:res.typeStat[name] ? res.typeStat[name] : '0',
            score:'0'
          })
        }
        this.formatData();
        console.log(this.dataTypes,'this.dataTypes')
        this.dataCount = Number(res.count) || 0;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    regenerate(data){
      if(!this.isEdit) return;
      let params = {
        op:'regenerate',
        train_id:this.trainId,
        regenerate:'1'  //0否1是
      };
      if(data && data.id){
        params.regenerate_data_id = data.id;
      }
      if(data && data.regenerateFill){
        params.regenerate_fill = data.regenerateFill;
      }
      this.api.dataset.trainPublish(params).then((res)=>{
        this.$Message.success(this.$t('trainingcamp_exam_create_publish_op_sucess'));
        this.getList();
      })
    },
    showItem(data){
      data.show = !data.show;
    },
    changeTab(data){
      this.curTab = data;
      this.page = 1;
      this.keyword = '';

      if(this.curTab.id == 2){
        this.getCategoryList();
      }else{
        this.getDataList();
      }
    },
    addData(){
      if(!this.isEdit) return;
      this.page = 1;
      this.addDataModal = true;
    },
    changeKeyword(){
      this.page = 1;
      this.getDataList();
    },
    getDataList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        category_id:this.categoryId,
        train_id:this.trainId,
        keyword:this.keyword
      };
      this.loading = true;
      this.api.dataset.trainDataAll(params).then((res)=>{
        this.loading = false;
        this.allDataList = res.list;
        this.total = Number(res.count);


      }).catch((err)=>{
        this.loading = false;
      })
    },
    closeAddDataModal(){
      this.addDataModal = false;
    },
    changePage(page){
      this.page = page;
      this.getDataList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getDataList();
    },
    dele(data){
      if(!this.isEdit) return;
      this.curData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let params = {
        op:'release',
        train_id:this.trainId,
        data_id:this.curData.id,
      };
      this.modalLoading = true;
      this.api.dataset.datasetTrainDataDel(params).then((res)=>{
        this.modalLoading = false;
        this.$Message.success('删除成功');
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      })
    },
    addCurData(){
      let params = {
        train_id:this.trainId,
        data_id:this.addDataCur.id,
      }
      this.api.dataset.trainDataAdd(params).then((res)=>{
        this.$Message.success('添加成功');
        this.getDataList();
        this.getList();
      })
    },
    submit(type,unverify){
      if(type == 'next'){   //下一步
        if(this.nextLoading) return;
      }else if(type == 'save'){ //保存并退出
        if(this.saveLoading) return;
      }
      // let params = {
      //   op:'dataList',
      //   train_id:this.trainId,
      // };

      if(!unverify){
        for(let i=0;i<this.dataTypes.length;i++){
          if(this.dataTypes[i].val > 0 && this.dataTypes[i].score <= 0){
            this.$Message.warning('请输入题型分数');
            return;
          }
        }
      }
      let scoreStr = this.dataTypes.map((item)=>{
        return item.score;
      }).join(',')

      let params = {
        op:'dataList',
        train_id:this.trainId,
        score_str:scoreStr
      };

      if(type == 'next'){   //下一步
        this.nextLoading = true;
      }else if(type == 'save'){ //保存并退出
        this.saveLoading = true;
      }

      this.api.dataset.trainPublish(params).then((res)=>{
        if(type == 'next'){   //下一步
          this.nextLoading = false;
          this.$emit('next');
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = false;
          this.$emit('save');
        }

      }).catch((err)=>{
        if(type == 'next'){   //下一步
          this.nextLoading = false;
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = false;
        }
      })
    },
    next(){
      if(!this.isEdit) return;
      this.submit('next');
    },
    save(){

      this.submit('save',true);
    },
    pre(){
      this.$emit('pre')
    },
    closeDataDetail(){
      this.dataDetailModal = false;
    },
    showDetail(data){
      this.curData = data;
      this.dataDetailModal = true;
    },
    checkScore(index){
      this.dataTypes[index].score = util.oneDecimal(this.dataTypes[index].score);
      console.log(this.dataTypes[index].score,'========')
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        is_hide_children:1,
        type:this.categoryType,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondCategoryList();
      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        is_hide_children:1,
        type:this.categoryType,
      };

      this.api.dataset.categoryForm(data).then((res)=>{
        //二级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;
        this.getThirdCategoryList();
      })
    },
    getThirdCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        is_hide_children:1,
        type:this.categoryType,
      };

      this.api.dataset.categoryForm(data).then((res)=>{
        this.thirdCategoryList = [
          ...res.categoryThirdList
        ]
        this.curThirdCategoryId = this.thirdCategoryList[0].id;
        this.getDataList();
      })
    },
    changeFirst(data){
      this.curCategoryId = data.id;
      this.getSecondCategoryList();
    },
    changeSecond(data){
      this.cursecondCategoryId = data.id;
      this.getThirdCategoryList();
    },
    changeThird(data){
      this.curThirdCategoryId = data.id;
      this.getDataList();
    }
  }
}
</script>

<style scoped lang="scss">
.dataList{
  margin: 0 20px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  height: 100%;
  .dataList-l{
    padding: 20px;
    flex: 1;
    width: 0;
    margin-right: 10px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;
    .dataList-l-top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      >p{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .dataList-l-top-r{
        display: flex;
        justify-content: flex-start;

        .dataList-l-top-r-btn{
          margin-left: 10px;
          padding: 0 16px;
          height: 28px;
          background: #EEEEEE;
          border-radius: 4px;
          color: #666666;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          >img{
            margin-right: 4px;
          }
        }
        .active{
          background: #5782FF;
          color:#FFFFFF;
        }
      }
      .btn{
        width: 120px;
        height: 28px;
        background: #FE9200;
        border-radius: 4px;
        color: #FFFFFF;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
    }
    .dataList-cont-list{
      font-weight: bold;
      color: #333333;
      .dataList-cont-list-item{
        margin-top: 30px;

        .dataList-cont-list-item-tit{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          cursor: pointer;
          >p{
            margin-right: 6px;
            width: 4px;
            height: 16px;
            background: #4A79FF;
          }
          >img{
            margin-left: 10px;
          }

        }
        .dataList-cont-list-item-div{
          height:0px;
          transition:height 0.5s;
          overflow: hidden;
        }
        .dataList-cont-list-item-table{
          margin-top: 10px;
          width: 100%;

          >tr{
            width: 100%;
            height: 44px;
            border-bottom: 1px solid #E8EAEC;
            display: flex;
            align-items: center;

            >th:first-child{
              padding-left: 50px;
              flex: 1;
            }
            >th:nth-child(2),>td:nth-child(2){
              width: 160px;
              text-align: center;
            }
            //>th:nth-child(3),>td:nth-child(3){
            //  width: 160px;
            //  text-align: center;
            //}
            >td:first-child{
              flex: 1;
            }
          }
          >tr:first-child{
            height: 40px;
            background: #F8F8F9;
            border: 1px solid #E8EAEC;
            border-radius: 1px;
            text-align: center;
          }
          .data-tit{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #333333;
            height: 44px;
            .data-type{
              margin-right: 10px;
              width: 39px;
              height: 26px;
              text-align: center;
              line-height: 26px;
            }
            >div{
              flex: 1;
              width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .data-btn{
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            >div{
              margin-right: 10px;
              width: 80px;
              height: 28px;
              background: #EEEEEE;
              border-radius: 4px;
              color: #666666;
              text-align: center;
              line-height: 28px;
              cursor: pointer;
            }
          }

        }
      }
    }
  }
  .dataList-r{
    padding: 20px;
    width: 670px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;
    .dataList-r-tit{
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .dataList-r-num{
      margin-top: 30px;
      padding-left: 14px;
      width: 400px;
      height: 40px;
      background: #F6F7FA;
      border-radius: 6px;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      >span{
        padding: 0 5px;
        color:#5782FF;
        font-weight: bold;
      }
    }
    .dataList-r-cont{
      margin-top: 30px;
      .dataList-r-cont-nums{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .dataList-r-cont-num{
          margin-right: 10px;
          width: 120px;
          height: 32px;
          background: #F6F7FA;
          border-radius: 4px;
          font-weight: bold;
          color: #333333;
          text-align: center;
          line-height: 32px;
        }
      }

      .dataList-r-cont-tit{
        margin-bottom: 10px;
        color: #333333;
      }
      .dataList-r-cont-ratio{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #333333;

        .dataList-r-cont-ratio-div{
          padding: 10px 0;
          margin-right: 10px;
          width: 106px;
          //height: 70px;
          background: #F6F7FA;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          align-items: center;
          .dataList-r-cont-ratio-type{
            margin-bottom: 4px;
            font-weight: bold;
            font-size: 16px
          }
        }
        .dataList-r-cont-ratio-fg{
          margin: 0 8px;
        }
      }
    }
    .dataList-r-btn{
      margin-top: 60px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      >div{
        margin-left: 15px;
        width: 90px;
        height: 36px;
        border: 1px solid #999999;
        border-radius: 4px;
        color: #999999;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }
      .active{
        background: #4A79FF;
        border-radius: 4px;
        border:none;
        color: #FFFFFF;
      }
    }
  }

}
.search-img{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table{
  margin: 20px 0;
}
.page{
  text-align: center;
}
.add-data-tab{
  margin-bottom: 20px;
  border-bottom: 1px solid #EEEEEE;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: flex-start;
  >p{
    padding-bottom: 10px;
    margin-right: 30px;
    cursor: pointer;
  }
  .active{
    font-weight: bold;
    color: #4A79FF;
    border-bottom: 4px solid #5782FF;
  }
}
.search{
  margin-top: 20px;
  margin-bottom: 20px;
  .search-item{
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;

    .name{
      padding: 5px 0;
      width: 75px;
      text-align: right;
    }
    .cont{
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
          border-radius: 4px;
        }
      }
      .more{
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;
        .more-icon{
          transition: transform 0.3s linear;
        }
      }
    }
  }
}
</style>
<style>
.modal-render-data-type{
  width: 39px;
  height: 26px;
  text-align: center;
  line-height: 26px;
}
</style>
