<template>
<div class="chapters">
  <div class="chapters-l" v-if="mapId">
    <ChaptersList @change="changeChapter" :mapNodeIds="info.attr.map_node_ids || ''" :isEdit="isEdit"></ChaptersList>
  </div>
  <div class="chapters-r" :style="{width:mapId ? '670px' : 'auto',flex: mapId ? '' : 1}">
    <div class="chapters-r-tit">请选择试卷生成方式</div>
    <div class="chapters-r-num" >已选章节共计可抽取<span>{{ allDataCount }}题</span>生成试卷</div>
    <div class="chapters-r-cont">
      <p class="chapters-r-cont-tit">设置题总数：</p>
      <div>
        <Input v-model="dataCount" :disabled="true" placeholder="请输入题总数" style="width: 120px;margin-right: 12px" />
        <span>题</span>
      </div>
    </div>
    <div class="chapters-r-cont">
      <p class="chapters-r-cont-tit">设置题型数量：</p>
      <div class="chapters-r-cont-ratio">
        <div class="chapters-r-cont-ratio-div" v-for="item in dataTypes" :key="item.id">
          <p class="chapters-r-cont-ratio-type">{{item.name}}</p>
<!--          <Select v-model="type1" class="select" :disabled="!isEdit">-->
<!--            <Option v-for="item in numList" :value="item" :key="item">{{ item }}</Option>-->
<!--          </Select>-->
          <Input v-model="item.val" :disabled="!isEdit" placeholder="请输入" style="width: 80px;margin: 5px 0;" />
          <p>可选择{{item.num}}题</p>
        </div>
<!--        <div class="chapters-r-cont-ratio-fg">:</div>-->
<!--        <div class="chapters-r-cont-ratio-div">-->
<!--          <p class="chapters-r-cont-ratio-type">多选</p>-->
<!--          <Input v-model="type2" :disabled="!isEdit" placeholder="请输入" style="width: 80px;margin: 5px 0;" />-->

<!--          <p>可选择{{typeNum[2]}}题</p>-->
<!--        </div>-->
<!--        <div class="chapters-r-cont-ratio-div">-->
<!--          <p class="chapters-r-cont-ratio-type">判断</p>-->
<!--          <Input v-model="type3" :disabled="!isEdit" placeholder="请输入" style="width: 80px;margin: 5px 0;" />-->

<!--          <p>可选择{{typeNum[3]}}题</p>-->
<!--        </div>-->
      </div>

    </div>

    <div class="chapters-r-cont">
      <p class="chapters-r-cont-tit">设置难度比例：
        <el-switch
            v-model="levelSwitch"
            @change="changeSwitch"
        >
        </el-switch>
      </p>
      <div class="chapters-r-cont-ratio" v-if="levelSwitch">
        <div class="chapters-r-cont-ratio-div">
          <p class="chapters-r-cont-ratio-type">简单</p>
          <Select v-model="level1" class="select" @on-change="changeLevel1">
            <Option v-for="item in numList" :value="item" :key="item">{{ item }}</Option>
          </Select>
          <p>{{levelNum.easyNum}}题</p>
        </div>
        <div class="chapters-r-cont-ratio-fg">:</div>
        <div class="chapters-r-cont-ratio-div">
          <p class="chapters-r-cont-ratio-type">一般</p>
          <Select v-model="level2" class="select" @on-change="changeLevel2">
            <Option v-for="item in numList" :value="item" :key="item">{{ item }}</Option>
          </Select>
          <p>{{levelNum.normalNum}}题</p>
        </div>
        <div class="chapters-r-cont-ratio-fg">:</div>
        <div class="chapters-r-cont-ratio-div">
          <p class="chapters-r-cont-ratio-type">困难</p>
          <Select v-model="level3" class="select" @on-change="changeLevel3">
            <Option v-for="item in numList" :value="item" :key="item">{{ item }}</Option>
          </Select>
          <p>{{levelNum.difficultNum}}题</p>
        </div>
      </div>
    </div>
    <div class="chapters-r-btn" v-if="isEdit">
      <div @click="cancel">取消</div>
      <div class="active" @click="next">下一步</div>
    </div>
  </div>
</div>
</template>

<script>
import ChaptersList from '../../common/chaptersList';
export default {
  name: "chapters.vue",
  data(){
    return {
      info:{},
      numList:[0,1,2,3,4,5,6,7,8,9],
      level1:3,
      level2:5,
      level3:2,
      // type1:0,
      // type2:0,
      // type3:0,
      // dataCount:'',
      allChapterIds:[],
      nextLoading:false,
      saveLoading:false,
      mapId:'',
      allDataCount:0,
      typeNum:{
        1:0,
        2:0,
        3:0,
      },
      categoryId:'',
      dataTypes:[],
      levelSwitch:false
    }
  },
  components:{
    ChaptersList
  },
  props:{
    trainId:{
      type:String,
      default:'',
    },
    detail:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    isEdit:{
      type:Boolean,
      default:true,
    }
  },
  computed:{
    dataCount(){
      let num = 0;
      this.dataTypes.forEach((item)=>{
        num += Number(item.val);
      })
      return num;
    },
    // typeNum(){
    //   let radioNum = this.type1 + this.type2 + this.type3 == 0 ? 0 : Math.floor(this.dataCount/(this.type1 + this.type2 + this.type3) * this.type1);
    //   let multipleNum = this.type1 + this.type2 + this.type3 == 0 ? 0 : this.type3 == 0 ? Number(this.dataCount) - Math.floor(this.dataCount/(this.type1 + this.type2 + this.type3) * this.type1)  : Math.floor(this.dataCount/(this.type1 + this.type2 + this.type3) * this.type2);
    //   let judgeNum = this.type3 == 0 ? 0 : Number(this.dataCount) - Math.floor(this.dataCount/(this.type1 + this.type2 + this.type3) * this.type1) - Math.floor(this.dataCount/(this.type1 + this.type2 + this.type3) * this.type2);
    //   return {
    //     radioNum,
    //     multipleNum,
    //     judgeNum
    //   }
    // },
    levelNum(){
      let easyNum = (this.level1 + this.level2 + this.level3) == 0 ? 0 : Math.floor(this.dataCount/(this.level1 + this.level2 + this.level3) * this.level1);
      let normalNum =(this.level1 + this.level2 + this.level3) == 0 ? 0 : Math.floor(this.dataCount/(this.level1 + this.level2 + this.level3) * this.level2);
      let difficultNum = this.level3 == 0 ? 0 : (this.dataCount - easyNum - normalNum);
      return {
        easyNum,
        normalNum,
        difficultNum
      }
    },
  },
  watch:{
    detail:{
      handler(newV,oldV){
        if(newV.id){
          this.info = newV;
          this.categoryId = this.info.generate_category_id;
          this.initData();
          this.getDataCont();
        }
      },
      immediate:true,
    }
  },
  created(){
    this.mapId = (this.$route.query.mapId == 0 || !this.$route.query.mapId) ? '' : this.$route.query.mapId;
    this.categoryId = this.$route.query.categoryId || '';
    if(this.categoryId){
      this.getDataCont();
    }
  },
  methods:{
    changeLevel1(){
      if((this.level1 + this.level2 + this.level3) == 0){
        this.$message.error('请至少选择一个难度');
        this.level1 = 1;
        return;

      }
    },
    changeLevel2(){
      if((this.level1 + this.level2 + this.level3) == 0){
        this.$message.error('请至少选择一个难度');
        this.level2 = 1;
        return;

      }
    },
    changeLevel3(){
      if((this.level1 + this.level2 + this.level3) == 0){
        this.$message.error('请至少选择一个难度');
        this.level3 = 1;
        return;

      }
    },
    changeSwitch(){
      if(this.levelSwitch){
        this.level1 = 1;
        this.level2 = 1;
        this.level3 = 1;
      }
    },
    initData(){
      // this.dataCount = Number(this.info.data_count) || 100;
      if(this.info.level_str == '0,0,0,0,0'){
        this.levelSwitch = false;

      }else{
        this.levelSwitch = true;

        this.level1 = Number(this.info.level_str.split(',')[0]);
        this.level2 = Number(this.info.level_str.split(',')[1]);
        this.level3 = Number(this.info.level_str.split(',')[2]);
      }

      // this.type1 = Number(this.info.type_str.split(',')[0]) || 0;
      // this.type2 = Number(this.info.type_str.split(',')[1]) || 0;
      // this.type3 = Number(this.info.type_str.split(',')[2]) || 0;


      let arr = this.info.type_str.split(',') || [];
      this.dataTypes.forEach((item,index)=>{
        item.val = Number(arr[index]) < Number(item.num) ? arr[index] : item.num;
      })
    },
    getDataCont(){
      let params = {
        category_id:this.categoryId,
      };
      this.api.dataset.datasetCategoryDataCount(params).then((res)=>{
        this.allDataCount = res.data_count;
        if(res.data_types_count.length){
          res.data_types_count.forEach((item)=>{
            this.typeNum[item.type] = item.count;
          });
        }

        this.dataTypes = [];
        for(let name in res.data_types){
          let arr = res.data_types_count.filter((item)=>{
            return item.type == name;
          });
          this.dataTypes.push({
            name:res.data_types[name],
            id:name,
            val:0,
            num:arr.length ? arr[0].count : '0'
          })
        }
        this.initData();

      })
    },
    changeChapter(data){
      this.allChapterIds = data;
      let params = {
        map_id:this.mapId,
        node_id:this.allChapterIds.join(',')
      };
      this.api.dataset.datasetCategoryDataCount(params).then((res)=>{
        this.allDataCount = res.data_count;
        // if(res.data_types_count.length){
        //   res.data_types_count.forEach((item)=>{
        //     this.typeNum[item.type] = item.count;
        //   });
        // }
        this.dataTypes = [];
        for(let name in res.data_types){
          let arr = res.data_types_count.filter((item)=>{
            return item.type == name;
          });
          this.dataTypes.push({
            name:res.data_types[name],
            id:name,
            val:0,
            num:arr.length ? arr[0].count : '0'
          })
        }
        this.initData();

      })
    },
    submit(type,unverify){
      if(type == 'next'){   //下一步
       if(this.nextLoading) return;
      }else if(type == 'save'){ //保存并退出
        if(this.saveLoading) return;
      }
      if(!unverify){
        if(!this.allChapterIds.length && this.mapId){
          this.$Message.warning('请选择章节');
          return;
        }
        if(!this.dataCount){
          this.$Message.warning(this.$t('trainingcamp_exam_create_rule_warn_total'));
          return;
        }


        for(let i=0;i<this.dataTypes.length;i++){

          if(Number(this.dataTypes[i].val) > Number(this.dataTypes[i].num)){
            this.$Message.warning(this.dataTypes[i].name + '数量不能大于可选题数');
            return;
          }
        }

      }
      let typeStr = this.dataTypes.map((item)=>{
        return item.val;
      }).join(',')
      let levelStr = '';
      if(this.levelSwitch){
        levelStr = this.level1 + ',' + this.level2 + ',' + this.level3 +':' + this.levelNum.easyNum + ',' + this.levelNum.normalNum + ',' + this.levelNum.difficultNum;
      }else{
        levelStr = '0,0,0:0,0,0';
      }

      let params = {
        op:'dataRule',
        train_id:this.trainId,
        data_count:this.dataCount,
        type_str:typeStr,
        level_str:levelStr,
        map_node_ids_str:this.allChapterIds.join(','),
      };
      if(type == 'next'){   //下一步
        this.nextLoading = true;
      }else if(type == 'save'){ //保存并退出
        this.saveLoading = true;
      }
      this.api.dataset.trainPublish(params).then((res)=>{
        if(type == 'next'){   //下一步
          this.nextLoading = false;
          this.$emit('next',{trainId:res.train_id});
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = false;
          this.$emit('save');
        }
      }).catch((err)=>{
        if(type == 'next'){   //下一步
          this.nextLoading = false;
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = false;
        }
      })
    },
    next(){
      if(!this.isEdit) return;
      if(Number(this.allDataCount) < Number(this.dataCount) && this.mapId){
        this.$Message.warning('设置题总数不能大于已选章节题目数');
        return;
      }
      this.submit('next');
    },
    save(){
      this.submit('save',true);
    },
    cancel(){
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped lang="scss">
.chapters{
  margin: 0 20px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  height: 100%;
  .chapters-l{
    padding: 20px;
    flex: 1;
    width: 0;
    margin-right: 10px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;
    .chapters-l-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >p{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .btn{
        width: 120px;
        height: 28px;
        background: #FE9200;
        border-radius: 4px;
        color: #FFFFFF;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
    }
    .chapters-cont-list{
      margin-top: 20px;
      font-weight: bold;
      color: #333333;
      .chapters-list-item{
        margin-bottom: 10px;

        .chapters-list-item-tit{
          padding: 0 20px;
          height: 41px;
          background: #F6F7FA;
          color: #333333;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #dcdee2;

          .chapters-list-item-tit-btn{
            width: 60px;
            height: 28px;
            background: #5782FF;
            border-radius: 4px;
            color: #FFFFFF;
            text-align: center;
            line-height: 28px;
            cursor: pointer;
          }
        }
        .chapters-list-item-cont{
          margin-top: 10px;
          height:0px;
          transition:height 0.5s;
          overflow: hidden;

          .chapters-list-item-cont-li{
            color: #666666;
            height: 130px;
            border-bottom: 1px solid #E0E0E0;

            .chapters-list-item-cont-li-tit{
              margin-bottom: 10px;
              font-size: 28px;
              color: #333333;
            }
            .chapters-list-item-cont-li-btn{
              width: 130px;
              height: 50px;
              background: #4A79FF;
              border-radius: 25px;
              font-weight: 500;
              color: #FFFFFF;
              text-align: center;
              line-height: 50px;
            }
          }
          .chapters-list-item-cont-li:last-child{
            border-bottom:none;
          }
        }
      }
    }
  }
  .chapters-r{
    padding: 20px;
    width: 556px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;
    .chapters-r-tit{
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .chapters-r-num{
      margin-top: 30px;
      padding-left: 14px;
      width: 400px;
      height: 40px;
      background: #F6F7FA;
      border-radius: 6px;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      >span{
        padding: 0 5px;
        color:#5782FF;
        font-weight: bold;
      }
    }
    .chapters-r-cont{
      margin-top: 30px;
      .chapters-r-cont-tit{
        margin-bottom: 10px;
        color: #333333;
      }
      .chapters-r-cont-ratio{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #333333;

        .chapters-r-cont-ratio-div{
          margin-right: 10px;
          width: 106px;
          height: 113px;
          background: #F6F7FA;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          align-items: center;
          .select{
            margin: 8px 0;
            width: 60px;
          }
          .chapters-r-cont-ratio-type{
            font-weight: bold;
            font-size: 16px
          }
        }
        .chapters-r-cont-ratio-fg{
          margin: 0 8px;
        }
      }
    }
    .chapters-r-btn{
      margin-top: 60px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      >div{
        width: 90px;
        height: 36px;
        border: 1px solid #999999;
        border-radius: 4px;
        color: #999999;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }
      .active{
        margin-left: 15px;
        background: #4A79FF;
        border-radius: 4px;
        border:none;
        color: #FFFFFF;
      }
    }
  }
}
</style>
<!--<style>-->
<!--.chapters .el-tree-node__content{-->
<!--  height: 44px !important;-->
<!--}-->
<!--</style>-->
